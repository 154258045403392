import type { Locales } from '$lib/routes'
import type { ParamMatcher } from '@sveltejs/kit'
import { PUBLIC_WOLTAIR_WEB_CODE } from '$env/static/public'

const JOB_OFFERS_LOCALES = {
	WOLTAIR_CZ: 'volna-mista',
	WOLTAIR_PL: 'oferty-pracy',
	WOLTAIR_DE: 'karriere',
	WOLTAIR_IT: 'lavora-con-noi'
}

export const JOB_OFFERS = JOB_OFFERS_LOCALES[PUBLIC_WOLTAIR_WEB_CODE as keyof Locales]

export const match = ((param) => {
	return JOB_OFFERS === param
}) satisfies ParamMatcher
